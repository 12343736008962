export const debounce = (callback:any, wait = 750) => {
    let timeoutId:any = null

    return (...args: any) => {

        window.clearTimeout(timeoutId)

        timeoutId = window.setTimeout(() => {
            callback(...args)
        }, wait)
    }
}