import { defineStore } from 'pinia'

import { QueryParams } from '~/interfaces/query-parameters'
import { Filter, Filters, Hit, JobsState } from '~/js/interface/job'
import { Location } from '~/js/interface/location'

import { useQueryArrayUrlify, useRemoveQueryUrlify, useQueryUrlify, useResetQuery } from '~/js/composables/query-parameters'
import { useTypesenseClient } from '~/js/composables/typesense'

export const useJobsStore = defineStore('jobs', {
    state: (): JobsState => ({
        coords: null,
        loading: false,
        facets: [],
        filters: {
            searchQuery: '',
            filterQuery: '',
            hidden_hits: '',
            kilometers: {
                field: 'coordinates',
                value: '',
                combination: '&&'
            },
            location: {
                field: 'coordinates',
                value: '',
                city: '',
                combination: '&&'
            },
            contractTypes: {
                field: 'contract_type',
                values: [],
                combination: '&&'
            },
            offices: {
                field: 'office',
                values: [],
                combination: '&&'
            },
            officeStatus: {
                field: 'office_status',
                value: 'live',
                combination: '&&'
            },
            sectors: {
                field: 'sector',
                values: [],
                combination: '&&'
            },
            workRegimes: {
                field: 'work_regime',
                values: [],
                combination: '&&'
            },
        },
        pagination: {
            current: 0,
            totalPages: 0,
            totalResults: 0,
            foundResults: 0,
            itemsPerPage: 8,
        },
        sorting: {
            type: `post_date_timestamp`,
            order: 'desc',
        },
        index: `g4j_${window.typesense_environment}_jobs`,
        posts: null,
        popular: null,
        useQueryParams: false,
        geoRadius: 25,
    }),
    actions: {
        async fetch(reset = false, queryParams?: Array<QueryParams>) {
            this.loading = true

            // set URL query parameters if existing
            if (queryParams && this.useQueryParams) {
                // this.setPagination(0, false)
                this.setQueryFilters(queryParams)
                // this.setPagsination(this.pagination.current, false)
            } else {
                this.setFiltersQuery()

                if (reset) {
                    this.posts = null
                    this.setPagination(0, false)
                } else {
                    this.setPagination(this.pagination.current+1, false)
                }
            }

            if (this.filters.searchQuery !== '') {
                this.sorting = {
                    type: `_text_match`,
                    order: 'desc',
                }
            }
            else if (this.coords || this.filters.location.value !== '') {
                if (this.filters.location.value) {
                    this.sorting = {
                        type: `coordinates(${this.filters.location.value})`,
                        order: 'asc',
                    }
                } else {
                    this.sorting = {
                        type: `coordinates(${this.coords.latitude}, ${this.coords.longitude})`,
                        order: 'asc',
                    }
                }
            } else {
                this.sorting = {
                    type: `post_date_timestamp`,
                    order: 'desc',
                }
            }

            const client = useTypesenseClient()

            const searchRequests = {
                searches: [
                    {
                        collection: this.index,
                        facet_by: "sector,contract_type,work_regime",
                        page: 1,
                        per_page: 1,
                        max_facet_values: 100,
                        q: "*",
                        sort_by: "_text_match:desc",
                    },
                    {
                        collection: this.index,
                        exclude_fields: "embedding",
                        facet_by: 'sector,contract_type,work_regime,city,post_code',
                        hidden_hits: this.filters.hidden_hits,
                        filter_by: this.filters.filterQuery,
                        page: this.pagination.current,
                        per_page: this.pagination.itemsPerPage,
                        q: this.filters.searchQuery || '*',
                        query_by: "title,description,reference", //embedding
                        max_facet_values: 100,
                        sort_by: (this.filters.searchQuery !== '' && this.filters.location.value !== '') ? `coordinates(${this.filters.location.value}):asc,_text_match:desc` : `${this.sorting.type}:${this.sorting.order}`,
                    },
                ]
            }

            await client
                .multiSearch
                .perform(searchRequests)
                .then((results: any) => {
                    const filteredResults = results.results[1] ?? []
                    const facets = results.results[0].facet_counts ?? []

                    this.facets = facets
                    this.pagination.current = filteredResults.page
                    this.pagination.totalPages =  this.pagination.itemsPerPage ? Math.ceil(filteredResults.found / this.pagination.itemsPerPage) : 0
                    this.pagination.foundResults = filteredResults.found
                    this.pagination.totalResults = filteredResults.out_of

                    if (filteredResults.hits) {
                        let hits: Array<Hit> = filteredResults.hits
                        
                        // random blog
                        const randomBlogIndex = this.getRandom()
                        hits = [...hits.slice(0,randomBlogIndex), {document: {type: 'blog', index: this.pagination.current - 1}}, ...hits.slice(randomBlogIndex)]
                        
                        // random tip
                        const randomTipIndex = this.getRandom(2,7,randomBlogIndex)
                        hits = [...hits.slice(0,randomTipIndex), {document: {type: 'tip', index: this.pagination.current - 1}}, ...hits.slice(randomTipIndex)]

                        // set results to the projects if it's a reset otherwise add the filteredResults to the existing ones
                        if (reset) {
                            this.posts = hits
                        } else {
                            this.posts = [...(this.posts || []), ...hits]
                        }
                    }

                    this.loading = false
                })
        },

        async popularQueries() {
            const client = useTypesenseClient()

            const searchRequests = {
                searches: [
                    {
                        collection: 'prod_popular_job_queries',
                        q: '*',
                        query_by: 'q',
                        limit: 3,
                    },
                ]
            }

            await client
                .multiSearch
                .perform(searchRequests)
                .then((response: any) => {
                    
                    const popularQueries = response.results[0].hits

                    if (popularQueries) {
                        this.popular = popularQueries
                    }
                })
        },

        getRandom(start = 2, end = 7, cantBe = -1) {
            const max = this.pagination.itemsPerPage - (this.pagination.itemsPerPage - end)
            const min = start

            let random = Math.floor(Math.random() * (max - min + 1) + min)

            if (random === cantBe || random - 1 == cantBe || random + 1 == cantBe) {
                if (random === cantBe) {
                    random = cantBe - 2 > 1 ? cantBe - 2 : cantBe + 2 < max ? cantBe + 2 : min
                }
                
                else if (random < cantBe) {
                    random = cantBe - 2 > 1 ? cantBe - 2 : min
                }

                else if (random > cantBe) {
                    random = cantBe + 2 < max ? cantBe + 2 : max
                }
            }

            return random
        },

        resetFilters(clearSector = true) {
            this.geoRadius = 25

            this.filters.searchQuery = ''

            this.filters.kilometers.value = ''
            this.filters.location.value = ''
            this.filters.location.city = ''

            this.filters.contractTypes.values = []
            this.filters.workRegimes.values = []

            if (clearSector) {
                this.filters.sectors.values = []
            }

            useResetQuery()

            this.fetch(true)
        },

        setPagination(page, fetch = true) {
            this.pagination.current = page

            if (this.useQueryParams) {
                useQueryUrlify('page', page)
            }
            
            if (fetch) {
                this.fetch()
            }
        },

        setPaginationPerPage(itemsPerPage: number) {
            this.pagination.itemsPerPage = itemsPerPage
        },

        setSearch(value: string, queryString: string) {
            this.filters[queryString] = value

            if (this.useQueryParams) {
                useQueryUrlify('functie', value)
            }
            
            this.fetch(true)
        },
        setLocation(location: Location|string, fetch = true, setUserLocation = true) {
            if (typeof location == 'string') {
                this.filters.location.value = ''
                this.filters.location.city = ''
            } else {
                this.filters.location.value = location.coordinates.join(', ')
                this.filters.location.city = location.city
            }

            if (this.useQueryParams) {
                useQueryUrlify('location', this.filters.location.value)
                useQueryUrlify('city', this.filters.location.city)
            }

            this.setFiltersQuery()

            if (fetch) {
                this.fetch(true)
            }
        },

        setDistance(kilometers: number, fetch = true) {
            this.geoRadius = kilometers

            this.setFiltersQuery()

            if (fetch) {
                this.fetch(true)
            }
        },
        
        setUserCoords(coords) {
            this.coords = coords
        },

        setHiddens(id: string) {
            this.filters.hidden_hits = id
        },

        setFilter(type: keyof Filters, value: string|null, fetch = true) {
            const settedFilter: Filter | string = this.filters[type] ?? null

            value = value ? value.toString() : ''

            switch (type) {
                case 'location':
                    settedFilter.value = value.toString()

                    if (this.useQueryParams) {
                        useQueryUrlify(type, value)
                    }

                    break
                case 'officeStatus':
                    settedFilter.value = value.toString()
                    break
                case 'kilometers':
                    if (this.coords) {
                        settedFilter.value = value
                    }
                    break
                default:
                    if (settedFilter.hasOwnProperty('values')) {

                        let parsedType = type
                        switch (type) {
                            case 'workRegimes':
                                parsedType = 'regimes'
                                break
                            case 'contractTypes':
                                parsedType = 'types'
                        }

                        if (settedFilter.values.indexOf(value) < 0) {
                            settedFilter.values.push(value)
                            if (this.useQueryParams) {
                                useQueryArrayUrlify(parsedType, value)
                            }
                        } else {
                            settedFilter.values = settedFilter.values.filter((item: string) => item !== value)
                            if (this.useQueryParams) {
                                useRemoveQueryUrlify(parsedType, value)
                            }
                        }
                    }
            }

            this.setFiltersQuery()

            if (fetch) {
                this.fetch(true)
            }
        },
        setFiltersQuery() {
            this.filters.filterQuery = ''

            // sectors
            if (this.filters.sectors.values.length > 0) {
                this.filters.filterQuery += this.filters.sectors.field + ':[' + this.filters.sectors.values.toString() + '] ' + this.filters.sectors.combination + ' '
            }

            // offices
            if (this.filters.offices.values.length > 0) {
                this.filters.filterQuery += this.filters.offices.field + ':[' + this.filters.offices.values.toString() + '] ' + this.filters.offices.combination + ' '
            }

            // office status
            if (this.filters.officeStatus.value) {
                this.filters.filterQuery += this.filters.officeStatus.field + ':' + this.filters.officeStatus.value + ' ' + this.filters.officeStatus.combination + ' '
            }

            // contract types
            if (this.filters.contractTypes.values.length > 0) {
                this.filters.filterQuery += this.filters.contractTypes.field + ':[' + this.filters.contractTypes.values.toString() + '] ' + this.filters.contractTypes.combination + ' '
            }

            // work regimes
            if (this.filters.workRegimes.values.length > 0) {
                this.filters.filterQuery += this.filters.workRegimes.field + ':[' + this.filters.workRegimes.values.toString() + '] ' + this.filters.workRegimes.combination + ' '
            }

            // geosearch
            if (this.filters.location.value) {
                this.filters.filterQuery += this.filters.location.field + `:(${this.filters.location.value}, ${this.geoRadius} km) ` + this.filters.location.combination + ' '
            }
            else if (this.filters.kilometers.value) {
                this.filters.filterQuery += this.filters.kilometers.field + `:(${this.coords.latitude},${this.coords.longitude}, ${this.filters.kilometers.value} km) ` + this.filters.kilometers.combination + ' '
            }

            // remove empty && and ||
            const ampersandCombination = '&& '
            if (this.filters.filterQuery.lastIndexOf(ampersandCombination) >= this.filters.filterQuery.length - 4) {
                this.filters.filterQuery = this.filters.filterQuery.substring(0, this.filters.filterQuery.lastIndexOf(ampersandCombination))
            }

            const pipesCombination = '|| '
            if (this.filters.filterQuery.lastIndexOf(pipesCombination) >= this.filters.filterQuery.length - 4) {
                this.filters.filterQuery = this.filters.filterQuery.substring(0, this.filters.filterQuery.lastIndexOf(pipesCombination))
            }
        },
        setQueryFilters(queryParams: Array<QueryParams>) {
            for (const key in queryParams as QueryParams[]) {
                const values = queryParams[key].values
                const value = queryParams[key].value
                let type: string = queryParams[key].type

                //convert type
                switch (type) {
                    case 'regimes':
                        type = 'workRegimes'
                        break
                    case 'types':
                        type = 'contractTypes'
                        break
                }

                // check if filter exists (UTM tags can be provided)
                const filter = this.filters[type as keyof Filters]
                if (type == 'city' || typeof filter !== 'undefined') {

                    // set filter values
                    if (values) {
                        switch (type) {
                            case 'regimes':
                                type = 'workRegimes'
                                break
                            case 'types':
                                type = 'contractTypes'
                                break
                        }

                        const filter = this.filters[type as keyof Filters]

                        if (filter.hasOwnProperty('values')) {
                            this.filters[type as keyof Filters].values = values.map((item: { name: string }) => item.name)
                        }

                        if (filter.hasOwnProperty('value')) {
                            if (typeof this.filters[type as keyof Filters].value === 'boolean') {
                                this.filters[type as keyof Filters].value = (values.map((item: { name: string }) => item.name)[0] === 'true')
                            } else {
                                this.filters[type as keyof Filters].value = values.map((item: { name: string }) => item.name)[0]
                            }
                        }
                    }

                    if (value) {
                        switch (type) {
                            case 'location':
                                this.filters[type as keyof Filters].value = value
                                break
                            case 'city':
                                this.filters.location.city = value
                                break
                            default:
                                this.filters[type as keyof Filters] = value
                        }
                    }
                }

                if (type == 'page') {
                    this.pagination.current = value
                }
            }
            this.setFiltersQuery()
        },
        setQueryParams(set: boolean) {
            this.useQueryParams = set
        }
    }
})