<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'

import { debounce } from '~/js/composables/debounce'
import { getGlobalTranslation } from '~/js/composables/translations'
// import { useSetQueryParams } from '~/js/composables/query-parameters'

import { Location } from '~/js/interface/location'

import { useLocationsStore } from '~/js/stores/locations'
import { useJobsStore } from '~/js/stores/jobs'
import { useOfficeStore } from '~/js/stores/offices'

import SvgLoader from '~/vue/_atoms/svgs/SvgLoader.vue'

interface Props {
    icon?: string,
    label: string
    loading?: boolean
    name?: string
    placeholder?: string
    preSearch?: string
    showDistance?: boolean
    showLabel?: boolean
    store?: string
    type: string
    utilities?: string,
}

const locationStore = useLocationsStore()
const jobStore = useJobsStore()
const officeStore = useOfficeStore()
const stores = ref({'job': jobStore, 'office': officeStore})

const props = withDefaults(defineProps<Props>(), { 
    icon: 'fas fa-magnifying-glass',
    loading: false,
    name: '',
    preSearch: '',
    showDistance: false,
    showLabel: false,
    store: 'job',
    utilities: '',
})
const search = ref(props.preSearch)
const { locations, loading } = storeToRefs(locationStore)
const { filters, geoRadius } = storeToRefs(stores.value[props.store] ?? jobStore)
const emit = defineEmits(['setQuery'])
const expanded = ref(false)
const selected = ref(false)

const handleInput = debounce((evt) => {
    if (evt.keyCode === 27 || evt.keyCode == 13 || evt.keyCode == 9) {
        return 
    }

    // pass search to store
    locationStore.setSearch(evt.target.value)

    if (search.value.length > 0) {
        expanded.value = true
    } else {
        expanded.value = false
    }
}, 250)

const clear = () => {
    search.value = ''
    selected.value = false
    emit('setQuery', '')
    onClose()
}

const onSetLocation = (loc: Location) => {
    search.value = loc.city
    selected.value = true
    expanded.value = false
    // locationStore.setLocation(loc)
    emit('setQuery', loc)
    onClose()
}

const onSetDistance = kilometers => {
    emit('setQuery', parseInt(kilometers.target.value))
}

const onClose = () => {
    expanded.value = false

    if (!selected.value) {
        search.value = ''
    }
}

watch(() => filters.value.location.city, (newValue) => {
    search.value = newValue
    selected.value = search.value
})

// onMounted(async () => {
//     const location = useSetQueryParams().find(query => query.type == 'location')
//     console.log(location)
//     // locationStore.fetch(useSetQueryParams())
// })
</script>

<template>

    <fieldset 
        :class="['w-full sm:max-w-xs sm:flex items-center relative', utilities]"
        v-click-away="onClose"
        v-on:keyup.esc="onClose"
    >
        <legend class="sr-only">{{ label }}</legend>
        <label 
            :class="[
                'block h-full grow',
                showDistance ? 'mb-4 sm:mb-0' : '',
            ]"
        >
            <span :class="showLabel ? 'font-primary text-purple-600' : 'sr-only'">
                {{ label }}
            </span>
            <span class="relative block">

                <!-- icon / loader -->
                <span
                    :class="[
                        icon && !loading ? 'opacity-100' : 'opacity-0',
                        'text-purple-600 absolute left-4 top-1/2 -translate-y-1/2',
                    ]"
                >
                    <i :class="icon" aria-hidden="true"/>
                </span>
                <span
                    :class="[
                        loading ? 'opacity-100' : 'opacity-0',
                        'absolute left-3 top-1/2 -translate-y-1/2 text-purple-600',
                    ]"
                >
                    <SvgLoader />
                </span>

                <input
                    v-model="search"
                    :class="[
                        'inline-flex items-center',
                        'h-[44px] w-full',
                        'pl-10 pt-1',
                        'bg-white',
                        'rounded-none border border-purple-600',
                        'text-black font-primary text-sm md:text-base',
                        'focus:ring-4 focus:ring-blue-300 focus:outline-none',
                        'print:hidden',
                    ]"
                    :name="name ?? label"
                    :placeholder="placeholder ?? label"
                    autocomplete="off"
                    @keyup="handleInput"
                >

                <button
                    :class="[
                        search.length > 0 && !loading ? 'opacity-100' : 'opacity-0',
                        'absolute px-4 right-0 top-1/2 -translate-y-1/2 py-2 text-purple-600',
                        'focus:text-blue-600'
                    ]"
                    :tabindex="search.length > 0 && !loading ? 0 : -1"
                    @click="clear"
                    :aria-label="getGlobalTranslation('Maak de zoek vrij')"
                    type="button"
                >
                    <i class="fas fa-times" />
                </button>
            </span>

            <div 
                v-if="expanded && locations" 
                class="mt-1 absolute z-20 w-full bg-white z-10 max-h-[200px] overflow-y-auto scrollbar-hide border-2 border-yellow-600 rounded-sm rounded-br-2xl"
            >
                <button
                    v-for="location in locations"
                    :aria-label="location.document.city"
                    class="block w-full py-2 px-4 text-left group"
                    @click="() => onSetLocation(location.document)"
                    type="button"
                >
                    <span class="block pt-1 font-primary group-focus:text-blue-600 group-hover:text-yellow-700">{{ location.document.post_code }} - {{ location.document.city }}</span>
                </button>
            </div>
        </label>

        <label 
            class="relative block h-full"
            v-if="showDistance"
        >
            <span :class="showLabel ? 'font-primary text-purple-600' : 'sr-only'">
                {{ getGlobalTranslation('Afstand') }}
            </span>

            <div class="relative">
                <select
                    :class="[
                        'h-[44px]',
                        'block print:hidden',
                        'px-3 pt-1 pr-10 ',
                        'text-black font-primary text-sm md:text-base',
                        'focus:ring-4 focus:ring-blue-300 focus:outline-none',
                        'relative focus:z-10',
                        'w-full appearance-none',
                        'border border-purple-600',
                        'sm:border-l-0',
                        'disabled:text-gray-500',
                        'disabled:border-purple-200',
                        '[-webkit-appearance:none]'
                    ]"
                    :disabled="!selected"
                    v-model="geoRadius"
                    @change="onSetDistance"
                >
                    <option value="5">5 km</option>
                    <option value="10">10 km</option>
                    <option value="15">15 km</option>
                    <option value="25">25 km</option>
                    <option value="35">35 km</option>
                    <option value="50">50 km</option>
                    <option value="100">100 km</option>
                </select>

                <span 
                    :class="[
                        'absolute right-4 text-purple-600 top-1/2 -translate-y-1/2 z-20 pointer-events-none',
                        !selected ? 'opacity-40' : '',
                    ]" 
                    aria-hidden="true"
                >
                    <i class="fas fa-chevron-down"></i>
                </span>
            </div>
        </label>
    </fieldset>
</template>