<script setup lang="ts">
import { ref } from 'vue'

import { getClasses } from '~/js/composables/classes'

import { Analytics } from '~/js/interfaces/analytics'

interface Props {
    content?: string,
    disabled?: boolean
    ga?: Analytics | null,
    type?: 'a' | 'button' | 'div',
    url?: string | null,
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), {
    content: '',
    disabled: false,
    size: 'default',
    type: 'button',
    url: null,
    utilities: '',
    utilitiesContent: '',
})

const classes = ref({
    _default: 'inline-flex items-center group',
    color: 'text-purple-600',
    border: 'border-b-2 border-b-yellow-500',
    focus: 'focus:border-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-200',
    font: 'font-primary text-sm md:text-base',
    hover: 'hover:border-blue-600',
    size: 'w-fit',
    utilities: props.utilities
})

</script>

<template>
    <component
        :is="type"
        :class="[
            getClasses(classes), 
            utilities
        ]"
        :href="url"
        :title="ga?.label ?? ''"
        :data-tag-category="type != 'div' ? ga?.category ?? '' : false"
        :data-tag-action="type != 'div' ? ga?.action ?? '' : false"
        :data-tag-label="type != 'div' ? ga?.label ?? '' : false"
    >
        <span class="whitespace-nowrap flex items-center">
            <slot></slot>
        </span>
    </component>
</template>