<script setup lang="ts">
import { ref, watch } from 'vue'

import { debounce } from '~/js/composables/debounce'

import SvgLoader from '~/vue/_atoms/svgs/SvgLoader.vue'

import { getGlobalTranslation } from '~/js/composables/translations'

interface Props {
    autocompleteResults?: Array<String>,
    popularQueries?: Array<String>,
    icon?: string,
    label: string
    loading?: boolean
    name?: string
    placeholder?: string
    preSearch?: string
    showAutocomplete?: boolean
    showLabel?: boolean
    type: string
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), { 
    autocompleteResults: null,
    popularQueries: null,
    icon: 'fas fa-magnifying-glass',
    loading: false,
    name: '',
    preSearch: '',
    showAutocomplete: false,
    showLabel: false,
    utilities: '',
})
const search = ref(props.preSearch)
const emit = defineEmits(['setQuery'])
const expanded = ref(false)

const handleInput = debounce(evt => {
    if (evt.keyCode === 27 || evt.keyCode == 13 || evt.keyCode == 9) {
        return 
    }

    expanded.value = true
    emit('setQuery', evt.target.value)
}, 250)

const onSetSearch = result => {
    search.value = result
    emit('setQuery', search.value)
    onClose()
}

const onClear = () => {
    if (expanded.value) {
        onClose()
        return
    }

    search.value = ''
    emit('setQuery', '')

    onClose()
}

const onClose = () => {
    expanded.value = false
}

watch(() => props.preSearch, (newValue) => {
    search.value = newValue;
})
</script>

<template>
    <fieldset 
        :class="['w-full md:max-w-xs', utilities]"
        v-click-away="onClose"
        v-on:keyup.esc="onClear"
    >
        <legend class="sr-only">{{ label }}</legend>
        <label class="relative block h-full">
            <span :class="showLabel ? 'font-primary text-purple-600' : 'sr-only'">
                {{ label }}
            </span>
            <span class="relative block">

                <!-- icon / loader -->
                <span
                    :class="[
                        icon && !loading ? 'opacity-100' : 'opacity-0',
                        'text-purple-600 absolute left-4 top-1/2 -translate-y-1/2',
                    ]"
                >
                    <i :class="icon" aria-hidden="true"/>
                </span>
                <span
                    :class="[
                        loading ? 'opacity-100' : 'opacity-0',
                        'absolute left-3 top-1/2 -translate-y-1/2 text-purple-600',
                    ]"
                >
                    <SvgLoader />
                </span>

                <!-- input -->
                <input
                    v-model="search"
                    :class="[
                        'inline-flex items-center',
                        'h-[44px] w-full',
                        'pt-1',
                        'bg-white',
                        'rounded-none border border-purple-600',
                        'text-black font-primary text-sm md:text-base',
                        'focus:ring-4 focus:ring-blue-300 focus:outline-none',
                        'print:hidden',
                        icon || loading ? 'pl-10' : 'pl-3',
                    ]"
                    :name="name ?? label"
                    :placeholder="placeholder ?? label"
                    autocomplete="off"
                    @keyup.prevent="handleInput"
                >
                
                <!-- clear -->
                <button
                    :class="[
                        search.length > 0 && !loading ? 'opacity-100' : 'opacity-0',
                        'absolute px-4 right-0 top-1/2 -translate-y-1/2 py-2 text-purple-600',
                        'focus:text-blue-600'
                    ]"
                    @click="onClear"
                    :aria-label="getGlobalTranslation('Maak de zoek vrij')"
                    :tabindex="search.length > 0 && !loading ? 0 : -1"
                    type="button"
                >
                    <i class="fas fa-times"></i>
                </button>
            </span>

            <div 
                v-if="(expanded && showAutocomplete && autocompleteResults && search.length > 3) || (expanded && popularQueries && popularQueries.length > 0)"
                class="mt-1 absolute w-full bg-white z-50 max-h-[400px] overflow-y-auto scrollbar-hide border-2 border-yellow-600 rounded-sm rounded-br-2xl"
            >
                <button
                    v-for="result in autocompleteResults"
                    :aria-label="result"
                    class="flex w-full py-2 px-4 text-left group"
                    @click="() => onSetSearch(result)"
                    type="button"
                >
                    <span class="block pt-1 font-primary group-focus:text-blue-600 group-hover:text-yellow-700">
                        {{ result }}
                    </span>
                </button>

                <span class="font-primary leading-normal tracking-wide flex px-4 text-purple-400 text-base mt-4" v-if="autocompleteResults.length === 0">
                    Geen zoekresultaten die overeenkomen
                </span>

                <hr class="bg-purple-300 h-px flex w-full my-4">

                <span class="font-primary leading-normal tracking-wide flex px-4 text-purple-400 text-sm">
                    Populaire zoekopdrachten
                </span>

                
                    <button
                        v-for="query in popularQueries"
                        :aria-label="query"
                        class="flex w-full py-2 px-4 text-left group gap-x-2 text-purple-400"
                        @click="() => onSetSearch(query.document.q)"
                        type="button"
                    >
                        <span>
                            <i class="fa-solid fa-arrow-trend-up"></i>
                        </span>

                        <span class="flex font-primary pt-1 text-base  group-focus:text-blue-600 capitalize">
                            {{ query.document.q }}
                        </span>
                    </button>
            </div>
        </label>
    </fieldset>
</template>