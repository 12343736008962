import { defineStore } from 'pinia'

import { Filter, OfficeState, Hit } from '~/js/interface/offices'
import { QueryParams } from '~/interfaces/query-parameters'
import { Location } from '~/js/interface/location'

import { useSearchParameters, useTypesenseClient } from '~/js/composables/typesense'
import { useQueryArrayUrlify, useRemoveQueryUrlify, useQueryUrlify } from '~/composables/query-parameters'

export const useOfficeStore = defineStore('offices', {
    state: (): OfficeState => ({
        loading: true,
        coords: null,
        facets: [],
        filters: {
            searchQuery: '',
            filterQuery: '',
            province: {
                field: 'province',
                value: null,
                combination: '&&'
            },
            location: {
                field: 'coordinates',
                value: '',
                city: '',
                combination: '&&'
            },
            postCode: {
                field: 'post_code',
                value: null,
                combination: '&&',
            }
        },
        pagination: {
            current: 0,
            totalPages: 0,
            totalResults: 0,
            foundResults: 0,
            itemsPerPage: 200,
        },
        sorting: {
            type: 'title',
            order: 'asc',
            selected: 'title',
        },
        index: `g4j_${window.typesense_environment}_offices`,
        geoRadius: 30,
        offices: null
    }),
    actions: {
        async fetch(reset = false, queryParams?: Array<QueryParams>) {
            this.loading = true
            let searchQuery = this.filters.searchQuery

            // set URL query parameters if existing
            if (queryParams) {
                this.setQueryFilters(queryParams)
            }

            if (reset) {
                this.pagination.current = 0
            } else {
                this.pagination.current += 1
            }

            if (this.filters.location.value !== '') {
                this.sorting.type = `coordinates(${this.filters.location.value})`
            } else {
                this.sorting.type = 'title'
            }

            const client = useTypesenseClient()
            const searchParameters = useSearchParameters(this.filters, this.sorting, 'title, address_line_1, address_line_2, address_line_3, city')

            const searchRequests = {
                searches: [
                    {
                        collection: this.index,
                        page: this.pagination.current,
                        facet_by: 'province',
                        per_page: this.pagination.itemsPerPage,
                        ...searchParameters
                    }
                ]
            }

            await client
                .multiSearch
                .perform(searchRequests)
                .then((results: any) => {
                    const filteredResults = results.results[0]
                    const facets = results.results[0].facet_counts

                    this.facets = facets
                    this.pagination.current = filteredResults.page
                    this.pagination.totalPages =  this.pagination.itemsPerPage ? Math.ceil(filteredResults.found / this.pagination.itemsPerPage) : 0
                    this.pagination.foundResults = filteredResults.found
                    this.pagination.totalResults = filteredResults.out_of

                    if (filteredResults.hits) {
                        const hits: Array<Hit> = filteredResults.hits

                        // set results to the projects if it's a reset otherwise add the filteredResults to the existing ones
                        if (reset) {
                            this.offices = hits
                        } else {
                            this.offices = [...(this.offices || []), ...hits]
                        }
                    }
                    
                    this.loading = false
                })

            this.filters.searchQuery = searchQuery
        },
        setPagination(page) {
            this.pagination.current = page
            this.fetch()
        },
        setSearch(value: string) {
            this.filters.searchQuery = value

            this.fetch(true)
        },
        setLocation(location: Location|string, fetch = true) {
            if (typeof location == 'string') {
                this.filters.location.value = ''
                this.filters.location.city = ''
            } else {
                this.filters.location.value = location.coordinates.join(', ')
                this.filters.location.city = location.city
            }

            this.setSort('location', false)
            this.setFiltersQuery()

            if (fetch) {
                this.fetch(true)
            }
        },
        setSort(sort: 'title'|'location', fetch = true) {
            this.sorting.selected = sort

            if (fetch) {
                this.fetch(true)
            }
        },
        setLoading(loading: boolean) {
            this.loading = loading
        },
        setUserCoords(coords) {
            this.coords = coords
            this.sorting.type = `coordinates(${this.coords.latitude}, ${this.coords.longitude})`
            this.fetch(true)
        },
        setFilter(type: keyof Filters, value: string) {
            const settedFilter: Filter | string = this.filters[type] ?? null

            if (typeof settedFilter !== 'string') {
                if (settedFilter.hasOwnProperty('value')) {
                    settedFilter.value = value
                }
            }

            this.setFiltersQuery()
            this.fetch(true)
        },
        setFiltersQuery() {
            this.filters.filterQuery = ''

            if (this.filters.province.value) {
                this.filters.filterQuery += this.filters.province.field + ':[' + this.filters.province.value + '] ' + this.filters.province.combination + ' '
            }

            if (this.filters.postCode.value) {
                this.filters.filterQuery += this.filters.postCode.field + ':[' + this.filters.postCode.value + ']'
            }

            if (this.filters.location.value) {
                this.filters.filterQuery += this.filters.location.field + `:(${this.filters.location.value}, ${this.geoRadius} km) ` + this.filters.location.combination + ' '
            }

            // remove empty && and ||
            const ampersandCombination = '&& '
            if (this.filters.filterQuery.lastIndexOf(ampersandCombination) >= this.filters.filterQuery.length - 4) {
                this.filters.filterQuery = this.filters.filterQuery.substring(0, this.filters.filterQuery.lastIndexOf(ampersandCombination))
            }

            const pipesCombination = '|| '
            if (this.filters.filterQuery.lastIndexOf(pipesCombination) >= this.filters.filterQuery.length - 4) {
                this.filters.filterQuery = this.filters.filterQuery.substring(0, this.filters.filterQuery.lastIndexOf(pipesCombination))
            }
        },
        setQueryFilters(queryParams: Array<QueryParams>) {
            for (const key in queryParams as QueryParams[]) {
                const values = queryParams[key].values
                const value = queryParams[key].value
                let type: string = queryParams[key].type

                if (values) {

                    if (type === 'q') {
                        type = 'searchQuery'
                    }

                    const filter = this.filters[type as keyof Filters]

                    if (filter || filter === '') {
                        if (filter.hasOwnProperty('values')) {
                            this.filters[type as keyof Filters].values = values.map((item: { name: string }) => item.name)
                        }

                        if (filter.hasOwnProperty('value')) {
                            if (typeof this.filters[type as keyof Filters].value === 'boolean') {
                                this.filters[type as keyof Filters].value = (values.map((item: { name: string }) => item.name)[0] === 'true')
                            } else {
                                this.filters[type as keyof Filters].value = values.map((item: { name: string }) => item.name)[0]
                            }
                        }

                        if (typeof filter == 'string') {
                            this.filters[type as keyof Filters] = values.map((item: { name: string }) => item.name)[0]
                        }
                    }
                }

                if (value) {
                    switch (type) {
                        case 'location':
                            this.filters[type as keyof Filters].value = value
                            break
                        case 'city':
                            this.filters.location.city = value
                            break
                        default:
                            this.filters[type as keyof Filters] = value
                    }
                    
                }
            }

            this.setFiltersQuery()
        }
    }
})