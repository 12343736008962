import { defineStore } from 'pinia'

import { LocationState, Hit } from '~/js/interface/location'
import { QueryParams } from '~/interfaces/query-parameters'
import { Location } from '~/js/interface/location'

import { useQueryArrayUrlify, useRemoveQueryUrlify, useQueryUrlify } from '~/js/composables/query-parameters'
import { useSearchParameters, useTypesenseClient } from '~/js/composables/typesense'

export const useLocationsStore = defineStore('locations', {
    state: (): LocationState => ({
        loading: false,
        facets: [],
        filters: {
            searchQuery: '',
            filterQuery: '',
        },
        pagination: {
            current: 0,
            totalPages: 0,
            totalResults: 0,
            foundResults: 0,
            itemsPerPage: 5,
        },
        sorting: {
            type: '_text_match',
            order: 'desc',
        },
        randomOrder: [],
        index: `g4j_${window.typesense_environment}_locations`,
        locations: null,
    }),
    actions: {
        async fetch(queryParams?: Array<QueryParams>) {
            this.loading = true
            this.locations = null

            // set URL query parameters if existing
            if (queryParams) {
                this.setQueryFilters(queryParams)
            }

            const client = useTypesenseClient()
            const searchParameters = useSearchParameters(this.filters, this.sorting, 'city, post_code')

            const searchRequests = {
                searches: [
                    {
                        collection: this.index,
                        ...searchParameters
                    }
                ]
            }

            await client
                .multiSearch
                .perform(searchRequests)
                .then((results: any) => {
                    setTimeout(() =>{
                        const filteredResults = results.results[0]

                        if (filteredResults.hits) {
                            const hits: Array<Hit> = filteredResults.hits

                            this.locations = hits
                        }

                        this.loading = false
                    }, 1000)
                })
        },
        setQueryFilters(queryParams: Array<QueryParams>) {
            for (const key in queryParams as QueryParams[]) {
                const value = queryParams[key].value
                const type: string = queryParams[key].type

                if (value) {
                    this.filters[type as keyof Filters] = value
                }
            }
        },
        setSearch(value: string) {
            this.filters.searchQuery = value
            this.fetch()
        },
        setLocation(location: Location|null) {
            if (location) {
                useQueryUrlify('location', location.city)
            } else {
                useQueryUrlify('location', null)
            }
        }
    }
})